$code-color: #333333;
@import 'bootstrap/scss/bootstrap.scss';
@import 'react-querybuilder/dist/query-builder.scss';

body {
  // margin: 0.5rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.queryBuilder {
  .form-control,
  .form-select {
    display: inline-block;
    width: auto;
  }
}

thead .MuiTableCell-root{
  background-color: #32527b;
  color: #fff;
  font-weight: 700;
  box-shadow: none!important;
}

.MuiBox-root{
  a:hover {
    color: #fff
  }
}

.MuiAccordionSummary-expandIconWrapper{
  svg{
    color: #fff;
  }
}

@media screen and (max-width: 568px) {
  .MuiGrid-grid-lg-6{
    padding-right: 0;
  }
}

/* Loading */
.loading {
  width: 50px;
	height: 50px;
	margin: 0 auto 30px;
	border:solid 5px #32527b;
	border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate; 
  -webkit-animation-duration: 1.0s; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name: rotate; 
  animation-duration: 1.0s; 
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to { 
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to { 
    -webkit-transform: rotate(360deg);
  }
}

.fieldsLoading{
  position:absolute;
  z-index: 9999;
  top:0; bottom:0; right:0; left:0;
  display:flex;
  align-items: center;
}